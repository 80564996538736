<template>
  <!-- 旧机弹窗 -->
  <el-dialog :title="title" :visible.sync="photoVisible" width="800px"
             :before-close="()=>$emit('close')"
             @close="$emit('close')" top="3%">
    <div class="dialog_photo_text" v-if="info">{{info}}</div>
    <div>
      <p class="photo_index">{{ `${i}/${photoArr.length}` }}</p>
      <viewer :images="photoArr" :options="optionsViewer">
        <el-carousel trigger="click" height="80vh" @change="(index)=>i=index+1" indicator-position="none">
          <el-carousel-item v-for="(item,i) in photoArr" :key="i" autoplay="false">
            <img :src="item" alt=""
                 style="width: 20vw;height: 80vh;margin: 0 auto;display: block;object-fit: contain">
          </el-carousel-item>
        </el-carousel>
      </viewer>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "photoPreview",
  props: {
    // 控制
    photoVisible: {
      type: Boolean,
      require: true
    },
    // 数据
    photoArr: {
      type: Array,
      default: () => [],
      require: true
    },
    // 标题
    title:{
      type:String,
      default:"查看照片"
    },
    // info信息
    info:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      // 当前幻灯片的索引
      i: 1,
      optionsViewer: {
        zIndex:99999999999,
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
      },
    }
  },
}
</script>
<style scoped lang="scss">
.dialog_photo_text {
  width: 80px;
  padding: 8px 0;
  line-height: 14px;
  font-size: 14px;
  background: #0981ff;
  color: #fff;
  text-align: center;
}

.photo_index {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #666666;
}

</style>
