<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      ref="billForm"
      :needBtnLoading="true"
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="100"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item label="支付时间" slot="times" style="margin: 0">
        <el-date-picker
          v-model="times"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="时间" slot="times2" style="margin: 0">
        <el-date-picker
          v-model="times2"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <div slot="AddSlot">
        <el-button
          size="small"
          :loading="isExport"
          type="success"
          icon="el-icon-download"
          @click="clickExcel"
          >导出Excel</el-button
        >
        <el-button
          v-if="tabType === '00'"
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="$router.push(`/payment/etcOrderNum?type=00`)"
          >充值</el-button
        >
        <el-button
          v-if="tabType === '01'"
          type="danger"
          size="small"
          @click="$router.push(`/payment/etcOrderNum?type=01`)"
          >下账</el-button
        >
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from "moment";
import { getHaoTime } from "@/utils/common";
export default {
  name: "Form",
  props: {
    tabType: {
      type: String,
      default: "00",
    },
  },
  data() {
    const startTime = moment()
      .day(moment().day() - 61)
      .format("YYYY-MM-DD 00:00:00");
    const endTime = moment().format("YYYY-MM-DD 23:59:59");
    const time = [startTime, endTime];
    return {
      isExport: false,
      initData: {
        // times2: time,//下账时间默认62天
      },
      times: null,
      times2: null,
      ExcelSeachParams: {},
      formItemList: [
        {
          key: "merchantName",
          type: "input",
          labelName: "商家名称",
          placeholder: "输入回收商家名称",
        },
        {
          key: "staffMobile",
          isNumber: true,
          type: "input",
          labelName: "联系电话",
          placeholder: "输入商户联系电话",
        },
        {
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "输入订单编号",
          valueFormat: "timestamp",
        },
        {
          slotName: "times",
        },
        {
          key: "rgChannel",
          labelName: "充值渠道",
          type: "select",
          placeholder: "请选择",
          option: [
            {
              label: "小程序",
              value: "00",
            },
            {
              label: "后台",
              value: "01",
            },
          ],
        },
      ],
      formRules: {
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.type) {
      this.tabType = this.$route.query.type;
      console.log(this.tabType, "this.props.tabType");
      if (this.tabType === "00") {
        //上账
        this.formItemList = [
          {
            key: "merchantName",
            type: "input",
            labelName: "商家名称",
            placeholder: "输入回收商家名称",
          },
          {
            key: "staffMobile",
            isNumber: true,
            type: "input",
            labelName: "联系电话",
            placeholder: "输入商户联系电话",
          },
          {
            key: "orderNo",
            type: "input",
            labelName: "订单编号",
            placeholder: "输入订单编号",
            valueFormat: "timestamp",
          },
          {
            slotName: "times",
          },
          {
            key: "rgChannel",
            labelName: "充值渠道",
            type: "select",
            placeholder: "请选择",
            option: [
              {
                label: "小程序",
                value: "00",
              },
              {
                label: "后台",
                value: "01",
              },
            ],
          },
        ];
      } else if (this.tabType === "01") {
        //下账
        this.formItemList = [
          {
            key: "merchantId",
            labelName: "商家名称",
            type: "selectFilterable",
            placeholder: "请选择",
            clear: true,
            option: [],
            clearFun: () => false,
          },
          {
            key: "adminId",
            labelName: "下账人",
            type: "selectFilterable",
            placeholder: "请选择",
            clear: true,
            option: [],
            clearFun: () => false,
          },
          {
            key: "orderNo2",
            type: "input",
            labelName: "账单流水号",
            placeholder: "请输入账单流水号",
          },
          {
            slotName: "times2",
          },
        ];
        this.getSelectList();
      }
    }
    this.getSelectList();
  },
  watch: {
    tabType: {
      handler(val, oldValue) {
        console.log(this.SeachParams, "表单里参数3333");
        console.log(val, "val111");
        this.$emit("changeNav", this.SeachParams);
        if (val === "00") {
          //上账
          this.formItemList = [
            {
              key: "merchantName",
              type: "input",
              labelName: "商家名称",
              placeholder: "输入回收商家名称",
            },
            {
              key: "staffMobile",
              isNumber: true,
              type: "input",
              labelName: "联系电话",
              placeholder: "输入商户联系电话",
            },
            {
              key: "orderNo",
              type: "input",
              labelName: "订单编号",
              placeholder: "输入订单编号",
              valueFormat: "timestamp",
            },
            {
              slotName: "times",
            },
            {
              key: "rgChannel",
              labelName: "充值渠道",
              type: "select",
              placeholder: "请选择",
              option: [
                {
                  label: "小程序",
                  value: "00",
                },
                {
                  label: "后台",
                  value: "01",
                },
              ],
            },
          ];
          const startTime = moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00");
          const endTime = moment().format("YYYY-MM-DD 23:59:59");
          let times = [startTime, endTime];
          this.times = [startTime, endTime];
          console.log(this.initData, "火火火ggggggg");
          this.handleConfirm({ times, ...this.initData });
        } else if (val === "01") {
          //下账
          this.formItemList = [
            {
              key: "merchantId",
              labelName: "商家名称",
              type: "selectFilterable",
              placeholder: "请选择",
              clear: true,
              option: [],
              clearFun: () => false,
            },
            {
              key: "adminId",
              labelName: "下账人",
              type: "selectFilterable",
              placeholder: "请选择",
              clear: true,
              option: [],
              clearFun: () => false,
            },
            {
              key: "orderNo2",
              type: "input",
              labelName: "账单流水号",
              placeholder: "请输入账单流水号",
            },
            {
              // key: "times2",
              // type: "daterange",
              // labelName: "时间",
              // placeholder: '请选择时间',
              // valueFormat: 'timestamp'
              slotName: "times2",
            },
          ];
          this.getSelectList();
          // 初始化时间
          const startTime = moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00");
          const endTime = moment().format("YYYY-MM-DD 23:59:59");
          let times2 = [startTime, endTime];
          this.times2 = [startTime, endTime];
          console.log(this.initData, "火火火ggggggg");
          this.handleConfirm({ times2, ...this.initData });
        }
      },
      immediate: true,
    },
  },
  methods: {
    dateChange(e) {
      console.log(e, "eeeee");
      this.times2 = e;
    },
    // 下拉列表
    getSelectList() {
      // 下账人列表
      _api.StaffManageList({ pageNum: 1, pageSize: 99999 }).then((r) => {
        const result = r.data.records.map((v) => {
          return {
            value: v.id,
            label: v.userName,
          };
        });
        const adminIdinIndex = this.formItemList.findIndex(
          (v) => v.key === "adminId"
        );
        if (adminIdinIndex >= 0) {
          this.formItemList[adminIdinIndex].option = result;
        }
      });
      // 回收商列表
      _api.listSimpleMerchantView({ pageNum: 1, pageSize: 99999 }).then((r) => {
        const result = r.data.map((v) => {
          return {
            value: v.merchantId,
            label: v.merchantName,
          };
        });
        const merchantIdinIndex = this.formItemList.findIndex(
          (v) => v.key === "merchantId"
        );
        console.log(merchantIdinIndex, "merchantIdinIndex");
        if (merchantIdinIndex >= 0) {
          this.formItemList[merchantIdinIndex].option = result;
        }
      });
    },
    handleConfirm(data, cd) {
      // cd();
      console.log("提交了form", data);
      console.log("提交了data.times2", data.times2);
      let startTime = "";
      let endTime = "";
      if (this.times2 === null) {
        (startTime = ""), (endTime = "");
      } else {
        (startTime = moment(this.times2[0]).startOf("day").format("x")),
          (endTime = moment(this.times2[1]).endOf("day").format("x"));
      }
      let startTime1 = "";
      let endTime1 = "";
      if (this.times === null) {
        (startTime1 = ""), (endTime1 = "");
      } else {
        (startTime1 = moment(this.times[0]).startOf("day").format("x")),
          (endTime1 = moment(this.times[1]).endOf("day").format("x"));
      }
      let SeachParams = {};
      SeachParams = {
        // ****
        merchantId: data.merchantId,
        adminId: data.adminId,
        // *****
        merchantName: data.merchantName,
        orderNo: data.orderNo, //订单号
        orderNo2: data.orderNo2, //账单流水号
        staffMobile: data.staffMobile,
        endTime1: endTime1,
        startTime1: startTime1,
        endTime: endTime,
        startTime: startTime,
        rgChannel: data.rgChannel ?? null,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      console.log(SeachParams, "SeachParams777777");
      this.ExcelSeachParams = SeachParams;
      // this.$emit("changeNav", SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    //设置时间戳
    setParamsTIme(params, key, startKey, endKey) {
      return {
        ...params,
        [startKey]: getHaoTime(params[key][0], "start"),
        [endKey]: getHaoTime(params[key][1], "end"),
        // [key]: null,
      };
    },
    // time时间是否重置
    resetTime(params, key, startKey, endKey) {
      if (Array.isArray(params[key])) {
        params = this.setParamsTIme(params, key, startKey, endKey);
      } else {
        params[key] = null;
        params[startKey] = null;
        params[endKey] = null;
      }
      return params;
    },
    // 导出账单
    clickExcel() {
      let ExcelApi = "";
      console.log(this.tabType, "方法方法ff");
      if (this.tabType === "00") {
        //上账
        ExcelApi = "getPcOrderExcel";
      } else {
        //下账
        ExcelApi = "merchantExcel";
      }
      let SeachParams = {};
      if (this.tabType === "00") {
        //上账
        SeachParams = {
          merchantId: this.ExcelSeachParams.merchantId,
          adminId: this.ExcelSeachParams.adminId,
          merchantName: this.ExcelSeachParams.merchantName,
          orderNo: this.ExcelSeachParams.orderNo, //订单号
          orderNo2: this.ExcelSeachParams.orderNo2, //账单流水号
          staffMobile: this.ExcelSeachParams.staffMobile,
          endTime: this.ExcelSeachParams.endTime1,
          startTime: this.ExcelSeachParams.startTime1,
          rgChannel: this.ExcelSeachParams.rgChannel ?? null,
          pageNum: 1,
          pageSize: 10,
        };
      } else {
        //下账
        SeachParams = {
          merchantId: this.ExcelSeachParams.merchantId,
          adminId: this.ExcelSeachParams.adminId,
          merchantName: this.ExcelSeachParams.merchantName,
          orderNo: this.ExcelSeachParams.orderNo, //订单号
          orderNo2: this.ExcelSeachParams.orderNo2, //账单流水号
          staffMobile: this.ExcelSeachParams.staffMobile,
          endTime: this.ExcelSeachParams.endTime,
          startTime: this.ExcelSeachParams.startTime,
          rgChannel: this.ExcelSeachParams.rgChannel ?? null,
          pageNum: 1,
          pageSize: 10,
        };
      }
      this.isExport = true;
      _api[ExcelApi](SeachParams)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              console.log(data);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName =
                this.tabType === "00" ? "充值订单.xlsx" : "下账订单.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.isExport = false;
        })
        .catch((err) => {
          this.isExport = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
