<template>
  <div class="class-list">
    <GlobalInfoBar title="收货次数账单" content="管理并记录回收商充值、上账、下账等账单" />
    <!-- 切换tab -->
    <div class="radiobtn">
      <div class="faultBtn">
        <div :class="type === '00' ? 'active' : 'none'" @click="btnClick('00')">
          上账
        </div>
        <div :class="type === '01' ? 'active' : 'none'" @click="btnClick('01')">
          下账
        </div>
      </div>
    </div>
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <PaySearchFrom @changeNav="changeNav" :tabType="type" />
      </div>
      <div class="caseOrder">
        <PayTable :search="search" :tabType="type" />
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import PaySearchFrom from "./Form";
import PayTable from "./table";
// import ClassListTable from "./Table";
export default {
  name: "class-list",
  components: { PaySearchFrom, PayTable },
  data() {
    return {
      type: '00',//'00'上账，'01'下账
      search: {}
    };
  },
  created() {
    if(this.$route.query.type){
      this.type=this.$route.query.type
    }
  },
  methods: {
    changeNav(e) {
      console.log(e,'search里参数666')
      this.search = e
    },
    btnClick(type) {
      this.type = type
      // this.search = {}
    }
  },
};
</script>

<style scoped lang="scss">
.radiobtn {
  margin-bottom: 20px;
  ;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .faultBtn {
    width: 260px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
}
</style>